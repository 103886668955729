import { useEffect } from 'react';
import Log from '../lib/Log';
import { isObject } from '../lib/Utils';

const logging = inp => {
  if (isObject(inp)) {
    Log.field(inp);
  } else {
    Log.log(inp);
  }
};

const useEffectLog = (inp, log) => {
  useEffect(
    () => {
      if (log) Log.groupFunc(log, () => logging(inp));
      if (!log) logging(inp);
    },
    [inp, log]
  );
};

export default useEffectLog;
