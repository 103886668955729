import { useAPIEffect as useLibraryAPIEffect } from '@askyourteam-nz/react-library/dist/hooks'; // eslint-disable-line import/no-internal-modules
import useSetLoadingBar from './useLoadingBar';

const wrapFn = (oldFn, newFn) => (...args) => {
  oldFn(...args);
  return newFn(...args);
};

const useAPIEffect = (func = () => {}, deps = []) => {
  const setLoadingBar = useSetLoadingBar();
  useLibraryAPIEffect(
    (api, ...args) => {
      api.oauth.beforeCallback = wrapFn(
        api.oauth.beforeCallback,
        () => void (api.current && setLoadingBar(true))
      );

      api.oauth.afterCallback = wrapFn(
        api.oauth.afterCallback,
        () => void (api.current && setLoadingBar(false))
      );

      api.oauth.cancelCallback = wrapFn(
        api.oauth.cancelCallback,
        () => void (api.current && setLoadingBar(false))
      );

      return func(api, ...args);
    },
    [...deps, setLoadingBar]
  );
};

export default useAPIEffect;
