import { useEffect } from 'react';
import PropTypes from 'prop-types';

const HookWrapper = (props) => {
  const {
    args,
    hook,
    onResult
  } = props;

  const result = hook(...args);

  useEffect(
    () => {
      onResult(result);
    },
    [result]
  );

  return <></>;
};

HookWrapper.propTypes = {
  args: PropTypes.array, // The arguments for the hook
  hook: PropTypes.func, // The hook function being tested
  onResult: PropTypes.func // Function taking the return of the hook
};

export default HookWrapper;
