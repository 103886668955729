import { useEffect, useRef } from 'react';
import Toast from '_Lib/Toast';

const useToast = (func, deps) => {
  const toast = useRef(new Toast());
  useEffect(
    () => func(toast.current),
    deps
  );
  useEffect(
    () => () => void (toast.current.isOpen() && toast.current.dismiss()),
    []
  );
};

export default useToast;
