import { useCallback, useState } from 'react';
import { TOAST_LEVEL } from '_Lib/Toast';
import { DATE_NUMBERS, SERVER_TASK_STATUS } from '_Lib/Enum';
import { sleep } from '_Lib/Utils';

import ManageAsyncPromise from '_Lib/ManageAsyncPromise';

const useTaskDownloadManager = (props) => {
  const [ExportDownloadManager] = useState(() => new ManageAsyncPromise());

  const {
    getTask,
    onStartDisplayProps,
    onResultDisplayProps,
    onErrorDisplayProps
  } = props;

  const createDownloadTask = useCallback(
    async api => {
      let task_id = await getTask(api);
      const poll_start_time = Date.now();
      let task;
      do {
        if (!api.current) return;
        await sleep(2000);
        task = await api.oauth.getAPI(`/api/v1/tasks/${task_id}`);
        if (task.status == SERVER_TASK_STATUS.FAILED) throw new Error('Request failed');
        if (Date.now() - poll_start_time > DATE_NUMBERS.MINUTE * 30) throw new Error('Request timed out');
      } while(task.status != SERVER_TASK_STATUS.COMPLETED);

      return api.oauth.handleS3SignedURLDownload(`/api/v1/tasks/${task_id}/download`);
    },
    [getTask]
  );

  const downloadQuestionsExport = useCallback(
    () => {
      ExportDownloadManager.start(
        {
          onStartDisplayProps: () => ({ children: 'Starting', ...onStartDisplayProps() }),
          onErrorDisplayProps: () => ({ children: 'Failed, click here to try again', ...onErrorDisplayProps() }),
          onResultDisplayProps: () => ({
            children: 'Finished',
            level: TOAST_LEVEL.EXPORT,
            autoClose: DATE_NUMBERS.SECOND * 10,
            ...onResultDisplayProps()
          }),
          fetchData: createDownloadTask
        }
      );
    },
    [createDownloadTask, onStartDisplayProps, onErrorDisplayProps, onResultDisplayProps]
  );

  return downloadQuestionsExport;
};

export default useTaskDownloadManager;
