import { useCallback } from 'react';
import { useMemoObject, useStateReducer, useAPICallback, useAPIEffect } from '..';

const UPDATE_CATEGORY = cat => ({ categories }) => {
  categories = [...categories];
  let i = categories.findIndex(({ id }) => id == cat.id);
  if (i < 0) return;
  categories[i] = { ...categories[i], ...cat };
  return ({ categories });
};

const useDemographicsAPI = ({ account_id }) => {
  const [state, setState] = useStateReducer(
    () => ({
      loading: false,
      error: false,
      categories: []
    }),
    'useDemographicsAPI'
  );

  useAPIEffect(
    api => {
      if (!account_id) return;
      setState({ loading: true });
      api.oauth.postAPI(`api/v1/accounts/${account_id}/library/demographic_categories_index`)
        .then(({ demographics: categories }) => api.current && setState({ categories, loading: false }))
        .catch(() => api.current && setState({ error: true, loading: false }));
    },
    [account_id, setState]
  );

  const fetchDemographicOptions = useAPICallback(
    ({ id }) => id,
    async (api, category, params) => {
      const { id, options } = category;
      if (options !== undefined) return void api.oauth.abort();
      return api.oauth
        .getAPI(`api/v1/accounts/${account_id}/library/demographic_categories/${id}/options`, { include_participant_count: 1, ...params })
        .then(({ options }) => api.current && setState(UPDATE_CATEGORY({ ...category, options })))
        .catch(() => {});
    },
    [account_id, setState]
  );

  const staleDemographicOptions = useCallback(
    category => setState(UPDATE_CATEGORY({ ...category, options: undefined })),
    [account_id, setState]
  );

  const staleAllDemographicOptions = useCallback(
    () => setState(
      ({ categories }) => {
        categories = [...categories.map(cat => ({ ...cat, options: undefined }))];
        return { categories };
      }
    ),
    [account_id, setState]
  );

  const funcs = useMemoObject({
    fetchDemographicOptions,
    staleDemographicOptions,
    staleAllDemographicOptions
  });

  return [state, funcs];
};

export default useDemographicsAPI;
