import { useReducer, useCallback } from 'react';
import Log, { formatTime, titleColor, spread } from '_Lib/Log';

const useLoggingReducer = (reducer, initialArg, init, { title } = {}) => {

  const reducerLog = useCallback(
    (state, action) => {
      const { _no_log, type } = action || {};
      if (_no_log) return reducer(state, action);
      if (title) {
        Log.groupCollapsed(
          `%caction     %c${title}\n%c${type} %c@ ${formatTime(new Date())}`,
          ...[
            'color: gray; font-weight: lighter;',
            'color: purple; font-weight: normal;',
            `color: ${titleColor(type)};`,
            'color: gray; font-weight: lighter;'
          ]
        );
      } else {
        Log.groupCollapsed(
          `%caction\n%c${type} %c@ ${formatTime(new Date())}`,
          ...[
            'color: gray; font-weight: lighter;',
            `color: ${titleColor(type)};`,
            'color: gray; font-weight: lighter;'
          ]
        );
      }

      Log.breadcrumb('reducer', type + (title? ` - ${title}`: ''));

      Log.groupCollapsed('trace');
      Log.trace();
      Log.groupEnd();

      Log.log('prev state', ...spread(state));
      Log.log('action    ', ...spread(action));

      let newState = reducer(state, action);

      Log.log('next state', ...spread(newState));
      Log.groupEnd();

      return newState;
    },
    [reducer]
  );
  return useReducer(reducerLog, initialArg, init);
};

export default useLoggingReducer;
