import { useMemo, useEffect, useCallback } from 'react';
import OAuth from '_Lib/OAuth';
import { useLoggingReducer, useAPICallback } from '..';
import { convertServerErrorsToMap } from '_Lib/Utils';
import { NOTIFICATION_TYPE } from '_Lib/Enum';

const init = ({ notifications = [] } = {}) => ({
  notifications,
  loading: false
});

const reducer = (state, { type, notifications, notification }) => {
  const s = () => {
    switch(type) {
      case 'FETCH_NOTIFICATIONS_BEGIN': return { notifications: [], loading: true };
      case 'FETCH_NOTIFICATIONS_SUCCESS': return { loading: false, notifications, error: false };
      case 'FETCH_NOTIFICATIONS_LOADING': return { loading: false, error: true };
      case 'ON_NOTIFICATION_CREATE': {
        notifications = [...state.notifications, notification];
        notifications.sort(({ created_at: a }, { created_at: b }) => a > b? 1: -1);
        return { notifications };
      }
      case 'ON_NOTIFICATION_UPDATE': {
        notifications = [...state.notifications];
        let i = notifications.findIndex(({ id }) => id == notification.id);
        if (i < 0) return {};
        notifications[i] = {
          ...notifications[i],
          ...notification,
          settings: {
            ...notifications[i].settings,
            ...notification.settings,
          }
        };
        return { notifications };
      }
      case 'ON_NOTIFICATION_DELETE': {
        let i = state.notifications.findIndex(({ id }) => id == notification.id);
        if (i < 0) return {};
        notifications = [...state.notifications.slice(0, i), ...state.notifications.slice(i+1)];
        return { notifications };
      }
    }
  };
  return { ...state, ...s() };
};

const useNotificationsAPI = ({ account_id, survey_id }, props) => {
  const [state, dispatch] = useLoggingReducer(reducer, props, init, { title: 'Notification API' });

  const getAll = useAPICallback(
    api => {
      if (account_id && survey_id) return api.oauth
        .getAPI(`api/v1/accounts/${account_id}/surveys/${survey_id}/survey_notifications`)
        .then(({ items: notifications }) => api.current && dispatch({ type: 'FETCH_NOTIFICATIONS_SUCCESS', notifications }))
        .catch(() => api.current && dispatch({ type: 'FETCH_NOTIFICATIONS_FAILURE' }));
    },
    [account_id, survey_id, dispatch]
  );

  useEffect(
    () => {
      dispatch({ type: 'FETCH_NOTIFICATIONS_BEGIN' });
      getAll();
    },
    [getAll]
  );

  const createNotification = useAPICallback(
    (api, notification) => {
      notification = { ...notification, survey_id };
      return new OAuth()
        .postAPI(`api/v1/accounts/${account_id}/surveys/${survey_id}/survey_notifications`, notification)
        .then(({ _status_code, ...notification }) => api.current &&  dispatch({ type: 'ON_NOTIFICATION_CREATE', notification }));
    },
    [survey_id, dispatch]
  );

  const updateNotification = useAPICallback(
    ({ id } = {}) => id,
    (api, notification, changes) => {
      const {
        id,
        settings,
        notification_type
      } = notification;
      changes = {
        ...changes,
        id,
        settings: {
          ...settings,
          ...changes.settings
        }
      };
      dispatch({ type: 'ON_NOTIFICATION_UPDATE', notification: changes });
      return api.oauth
        .putAPI(`api/v1/accounts/${account_id}/surveys/${survey_id}/survey_notifications/${id}`, changes)
        .then(({ _status_code, ...notification }) => {
          if (!api.current) return;
          dispatch({ type: 'ON_NOTIFICATION_UPDATE', notification: { ...notification, errors: undefined } });
          if (notification_type == NOTIFICATION_TYPE.INVITATION) getAll();
        })
        .catch(({ response: { data: { errors } = {} } = {} } = {}) => api.current && dispatch({ type: 'ON_NOTIFICATION_UPDATE', notification: { id, errors: convertServerErrorsToMap(errors) } }));
    },
    [survey_id, getAll, dispatch]
  );

  const deleteNotification = useAPICallback(
    ({ id } = {}) => id,
    (api, notification) => {
      const {
        id
      } = notification;
      dispatch({ type: 'ON_NOTIFICATION_DELETE', notification });
      return api.oauth
        .deleteAPI(`api/v1/accounts/${account_id}/surveys/${survey_id}/survey_notifications/${id}`)
        .catch(({ response: { data: { errors } = {} } = {} } = {}) => {
          if (!api.current) return;
          dispatch({ type: 'ON_NOTIFICATION_CREATE', notification: { ...notification, errors: convertServerErrorsToMap(errors) } });
        });
    },
    [survey_id, dispatch]
  );

  const dispatchNotificationsChange = useCallback(
    notifications => dispatch({ type: 'FETCH_NOTIFICATIONS_SUCCESS', notifications }),
    [dispatch]
  );

  const funcs = useMemo(
    () => ({
      createNotification, updateNotification, deleteNotification, dispatchNotificationsChange
    }),
    [createNotification, updateNotification, deleteNotification, dispatchNotificationsChange]
  );

  return [state, funcs];
};

export default useNotificationsAPI;
