
import { useAPIEffect } from '..';
import { useStateReducer } from '_Hooks';

const useParticipantInformationAPI = ({ account_id }) => {

  const [
    {
      participant_count,
      participant_count_warning_reached,
      participant_count_limit_reached,
      participant_count_limit,
      loading
    },
    setState
  ] = useStateReducer(
    () => ({
      loading: true
    })
  );

  useAPIEffect(
    api => {
      if (!account_id) return;
      setState({ loading: true });
      api.oauth
        .getAPI(`api/v1/accounts/${account_id}/participant_information`)
        .then(response => {
          if (!api.current) return;
          const {
            participant_count,
            participant_count_warning_reached,
            participant_count_limit_reached,
            participant_count_limit
          } = response || {};

          setState({ participant_count, participant_count_warning_reached, participant_count_limit_reached, participant_count_limit, loading: false });
        })
        .catch(() => {setState({ loading: false });});
    },
    [account_id, setState]
  );

  return {
    participant_count,
    participant_count_warning_reached,
    participant_count_limit_reached,
    participant_count_limit,
    loading
  };

};

export default useParticipantInformationAPI;
