import { useAPIEffect } from '..';
import { useAPICallback, useMemoObject, useStateReducer } from '_Hooks';
import { convertServerErrorsToMap } from '_Lib/Utils';

const useBannerAPI = ({ banner_type, resource_id, resource_type }, initialState) => {

  const [
    state,
    setState
  ] = useStateReducer(
    () => ({
      loading: false,
      banner: {},
      ...initialState
    }),
    'useBannerAPI'
  );

  useAPIEffect(
    async api => {
      if (!banner_type || !resource_id) return;
      setState({ loading: true });
      let banner;
      try {
        banner = await api.oauth
          .getAPI(`api/v1/banners?banner_type=${banner_type}&resource_type=${resource_type}&resource_id=${resource_id}`)
          .then(({ _status_code, ...action }) => action);
      } catch (error) {
        if (!api.current) return;
        setState({ loading: false });
      }

      if (!api.current) return;
      setState({ banner, loading: false });
    },
    [banner_type, resource_type, resource_id]
  );

  const hideBanner = useAPICallback(
    async (api, oldBanner) => {
      const {
        id: banner_id
      } = oldBanner;

      let {
        banner,
        errors
      } = await api.oauth
        .putAPI(
          'api/v1/banners',
          { banner_id }
        )
        .then(({ _status_code, ...banner }) => ({ banner }))
        .catch(
          ({ response: { data: { errors } = {} } = {} } = {}) => ({ errors: convertServerErrorsToMap(errors) })
        );
      if (!api.current) return;

      banner = { ...oldBanner, ...banner, errors };
      setState({ banner });
      return !errors;
    },
    [setState]
  );

  const funcs = useMemoObject({
    hideBanner
  });

  return  [state, funcs];

};

export default useBannerAPI;
