import { useMemoObject, useModuleEffect } from '..';

const useSurveyQuestionThemesAPI = (props) => {
  const {
    account_id,
    survey_id,
    user_id
  } = props;

  const [
    themesState
  ] = useModuleEffect(
    () => ({
      themes: [],
      loadingThemes: false
    }),
    (api, setState) => {
      if (!survey_id) return;
      setState({ loadingThemes: true });
      api.oauth
        .getAPI(
          `api/v1/reports/surveys/${survey_id}/themes`,
          { filters: { included_to_survey: '1' }, user_id }
        )
        .then(
          ({ themes }) => {
            if (!api.current) return;
            setState({ loadingThemes: false, themes });
          }
        )
        .catch(() => api.current && setState({ loadingThemes: false }));
    },
    `useSurveyQuestionThemesAPI - themes ${survey_id}`,
    [survey_id]
  );

  const [
    questionsState
  ] = useModuleEffect(
    () => ({
      questions: [],
      loadingQuestions: false
    }),
    (api, setState) => {
      if (!account_id || !survey_id) return;
      setState({ loadingQuestions: true });
      api.oauth
        .getAPI(
          `api/v1/accounts/${account_id}/surveys/${survey_id}/questions`,
          { filters: { included_to_survey: '1' }, user_id }
        )
        .then(
          ({ questions }) => {
            if (!api.current) return;
            setState({ loadingQuestions: false, questions });
          }
        )
        .catch(() => api.current && setState({ loadingQuestions: false }));
    },
    `useSurveyQuestionThemesAPI - questions ${account_id} ${survey_id} ${user_id}`,
    [survey_id]
  );

  const [
    groupsState
  ] = useModuleEffect(
    () => ({
      groups: [],
      loadingGroups: false
    }),
    (api, setState) => {
      if (!account_id || !survey_id) return;
      setState({ loadingGroups: true });
      api.oauth
        .getAPI(
          `api/v1/reports/surveys/${survey_id}/questions/groups`,
          { filters: { included_to_survey: '1' }, user_id }
        )
        .then(
          ({ groups }) => {
            if (!api.current) return;
            setState({ loadingGroups: false, groups });
          }
        )
        .catch(() => api.current && setState({ loadingGroups: false }));
    },
    `useSurveyQuestionThemesAPI - groups ${account_id} ${survey_id} ${user_id}`,
    [survey_id]
  );

  const [
    categoriesState
  ] = useModuleEffect(
    () => ({
      categories: [],
      loadingCategories: false
    }),
    (api, setState) => {
      if (!account_id || !survey_id) return;
      setState({ loadingCategories: true });
      api.oauth
        .getAPI(
          `/api/v1/reports/surveys/${survey_id}/categories?include_questions=1`,
          { filters: { included_to_survey: '1' }, user_id }
        )
        .then(
          ({ data }) => {
            if (!api.current) return;
            setState({ loadingCategories: false, categories: data });
          }
        )
        .catch(() => api.current && setState({ loadingCategories: false }));
    },
    `useSurveyQuestionThemesAPI - categories ${account_id} ${survey_id} ${user_id}`,
    [survey_id]
  );

  return useMemoObject({
    ...themesState,
    ...questionsState,
    ...groupsState,
    ...categoriesState
  });
};

export default useSurveyQuestionThemesAPI;
