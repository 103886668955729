import { useRef } from 'react';
// eslint-disable-next-line import/no-internal-modules
import { useLocationHash } from '@askyourteam-nz/react-library/dist/hooks';

export const useHashEvent = (fn) => {

  const previousHash = useRef();
  const [hash] = useLocationHash();

  if (previousHash.current != hash) {
    previousHash.current = hash;
    if (hash) fn(hash);
  }
};
