import { useState } from 'react';
import useFeatureFlags from './useFeatureFlags';

/**
 * PLEASE DO NOT USE THIS HOOK OFTEN
 *
 * This hook breaks the Rules Of Hooks around conditional rendering of hooks
 * By storing the state of the feature flag on mount, the hooks should never change during
 * the lifecycle of the component however. In theory making this safer.
 *
 * This hooks relies on the assumption that the feature flag is loaded and ready to use when
 * the hook first mounts.
 */
export const useFeatureFlagSwappedHooks = (
  featureFlagField,
  { useOldHook, useNewHook, condition },
  ...params
) => {
  const [{ loading, [featureFlagField]: ff }] = useFeatureFlags();
  if (loading && process.env.NODE_ENV == 'development') throw new Error(`Feature flags are still loading! ${useNewHook.name} will never be used`);

  const [stored_state_of_feature_flag_on_mount] = useState(() => (condition && ff));
  if (stored_state_of_feature_flag_on_mount) return useNewHook(...params); // eslint-disable-line react-hooks/rules-of-hooks
  return useOldHook(...params); // eslint-disable-line react-hooks/rules-of-hooks
};
