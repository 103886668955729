import { useModuleEffect } from '..';

const initialState = {
  user: {},
  loading: false
};

const useUserAPI = () => {
  return useModuleEffect(
    () => initialState,
    async (api, setState) => {
      setState({ user: {}, loading: true });
      return api.oauth
        .getAPI('api/v1/profile')
        .then(
          ({ _status_code, ...user }) => {
            if (!api.current) return;
            let currentSubdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
            let account = (user.accounts || []).find(({ subdomain }) => subdomain == currentSubdomain);
            user = { ...user, account_role: user.role, key_contact: account?.key_contact };
            if (account) user = { ...user, account_role: account.user_role };
            setState({ loading: false, user });
          }
        )
        .catch(() => api.current && setState(s => ({ ...s, loading: false })));
    },
    'useUserAPI',
    []
  );
};

export default useUserAPI;
