import { useCallback } from 'react';
import Log, { formatTime, titleColor, spread } from '_Lib/Log';
import { useStateReducer as useLibraryStateReducer } from '@askyourteam-nz/react-library/dist/hooks'; // eslint-disable-line import/no-internal-modules

const logState = (state, changes = {}, { no_log, type = 'STATE REDUCER' } = {}, title) => {
  let newState = { ...state, ...changes };
  if (no_log) return newState;

  Log.groupCollapsed(
    `%cstate      %c${title} %c@ ${formatTime(new Date())}\n%c${type} %c${Object.keys(changes).join(', ')}`,
    ...[
      'color: gray; font-weight: lighter;',
      'color: purple; font-weight: normal;',
      'color: gray; font-weight: lighter;',
      `color: ${titleColor(type)};`,
      'color: purple; font-weight: normal;'
    ]
  );

  Log.groupCollapsed('trace');
  Log.trace();
  Log.groupEnd();

  Log.log('prev state', ...spread(state));
  Log.log('changes   ', ...spread(changes));
  Log.log('next state', ...spread(newState));
  Log.groupEnd();

  return newState;
};

const useStateReducer = (init, title) => {
  const onChange = useCallback(
    ({ state, changes, options }) => logState(state, changes, options, title),
    [title]
  );
  return useLibraryStateReducer(
    init,
    {
      ...title && ({ onChange })
    }
  );
};

export default useStateReducer;
