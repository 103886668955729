import { useState, useCallback, useEffect } from 'react';
import { makeRandomID, isNumber } from '_Lib/Utils';

const LOADING = {
  current: {},
  setStates: {},
  setState: function (fn) {
    this.current = fn({ ...this.current });
    Object
      .values(this.setStates)
      .forEach(setState => void setState({ ...this.current }));
  },
  clear: function () {
    for (let key in this.current) {
      this.current[key] = false;
    }
    Object
      .values(this.setStates)
      .forEach(setState => void setState({ ...this.current }));
  }
};

export const useLoadingBarState = () => {
  const [id] = useState(() => makeRandomID('use-loading-bar-state--'));
  const [state, setState] = useState({});
  useEffect(
    () => {
      LOADING.setStates[id] = setState;
      return () => {
        delete LOADING.setStates[id];
      };
    },
    [id, setState]
  );
  const loading = Object.values(state).some(x => !!x);
  const progress = Math.min(0, ...Object.values(state).filter(x => isNumber(x))) || undefined;
  return { loading, progress };
};

const useSetLoadingBar = () => {
  const [id] = useState(() => makeRandomID('use-loading-bar-set--'));
  const setLoadingBar = useCallback(
    loading => LOADING.setState(
      current => ({
        ...current,
        [id]: loading
      })
    ),
    [id]
  );
  useEffect(
    () => () => setLoadingBar(false),
    [setLoadingBar]
  );
  return setLoadingBar;
};

export default useSetLoadingBar;
