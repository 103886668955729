import { useEffect } from 'react';
import { DEFAULT_LOCALE } from '_Lib/Enum';
import { useAPIEffect, useModuleState, useStateWithCallback } from '..';

const initialState = {
  account: { translations: [DEFAULT_LOCALE] },
  loading: true,
  failed: false
};

export const DEFAULT_ACCOUNT_ID = 'default_account_id';
export const SESSION_ACCOUNT_ID = 'session_account_id';

const saveAccountID = x => {
  localStorage.setItem(DEFAULT_ACCOUNT_ID, JSON.stringify(x));
  sessionStorage.setItem(SESSION_ACCOUNT_ID, x);
  return x;
};

export const useSetAccountAPI = ({ default_account_id } = {}) => {
  const [account_id, setAccountID] = useStateWithCallback(
    () => saveAccountID(
      (() => {
        if ((/^.+app/g).test(location.hostname)) return default_account_id;
        if (!/^[^\d]*$/.test(location.pathname)) return default_account_id;
        let id = parseInt(sessionStorage.getItem(SESSION_ACCOUNT_ID));
        if (id) return id;
        id = parseInt(localStorage.getItem(DEFAULT_ACCOUNT_ID));
        if (id) return id;
        return default_account_id;
      })()
    ),
    new_account_id => saveAccountID(new_account_id),
    []
  );

  useEffect(
    () => {
      const onFocus = () => void saveAccountID(account_id);
      window.addEventListener('focus', onFocus);
      return () => {
        window.removeEventListener('focus', onFocus);
      };
    },
    [account_id]
  );

  const [, setState] = useModuleState('useAccountAPI', initialState);

  useAPIEffect(
    api => {
      if (!account_id) return;
      setState({ account: { translations: [DEFAULT_LOCALE] }, loading: true });
      api.oauth
        .getAPI(`api/v1/accounts/${account_id}`)
        .then(({ _status_code, translations, ...account }) => {
          if (!translations) translations = [];
          translations = [DEFAULT_LOCALE, ...translations];
          setState({ account: { translations, ...account }, loading: false });
        })
        .catch(() => setState({ loading: false, failed: true }));
    },
    [account_id]
  );

  return setAccountID;
};

const useAccountAPI = () => useModuleState('useAccountAPI', initialState);

export default useAccountAPI;
