import { useStateReducer, useAPICallback } from '_Hooks';

export const useHubspotAPI = ({ account_id }) => {
  const [state, setState] = useStateReducer(
    () => ({
      loading: false
    }),
    'useFolderApi'
  );

  const createHubspotDeal = useAPICallback(
    async (api, params) => {
      if (!account_id) return;
      setState({ loading: true });
      const { success } = await api.oauth
        .postAPI(
          `/api/v1/accounts/${account_id}/create_hubspot_deal`,
          params
        )
        .catch(() => api.current && setState({ loading: false }));
      return success;
    },
    [account_id, setState]
  );

  return { ...state, createHubspotDeal };
};
