import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';

const SUPPORTED_TIME_ZONES = ['Auckland', 'Australia'];

const TIME_ZONES = {
  NEW_ZEALAND: 'Pacific/Auckland',
  PERTH: 'Australia/Perth',
  SYDNEY: 'Australia/Sydney'
};

export const useCSTAvailabilityHours = ({ time_zone }) => {
  const getTimeZoneAbbreviation = useCallback(
    (timeZone) => {
      return moment.utc().tz(timeZone.toString())
        .format('z');
    },
    []
  );

  const displayTimeZone = useMemo(
    () => {
      if (time_zone == undefined) return getTimeZoneAbbreviation(TIME_ZONES.NEW_ZEALAND);
      if (time_zone == TIME_ZONES.PERTH) return getTimeZoneAbbreviation(TIME_ZONES.SYDNEY);

      const supportedTimeZone = SUPPORTED_TIME_ZONES.some(supported_tz => time_zone?.includes(supported_tz));

      if (supportedTimeZone) {
        return getTimeZoneAbbreviation(time_zone);

      } else {
        return getTimeZoneAbbreviation(TIME_ZONES.NEW_ZEALAND);
      }
    },
    [time_zone, getTimeZoneAbbreviation]
  );

  // Format the available hours string
  const availableHours = `8am - 5pm (${displayTimeZone})`;

  return {
    CSTAvailabilityHours: availableHours
  };
};
